import React from "react";
import VRSEARCH from "./teasers/teaser-vrsearch.png";
import COLOR from "./teasers/teaser-color.png";
import CODESIGNREMINSICENCE from "./teasers/teaser-codesignreminsicence.png"
import REMINIBUDDY from "./teasers/teaser-reminibuddy.png"
import ASSESSOR from "./teasers/teaser-abilityassessor.png"


const Research = () => {
    return (
        <div className="each-container" id="research-container">
          <div className="research-part">
            <div id="research">
              <div className="title">Research</div>
              <div className="each-research">
                  <div className="left">
                    <img src={ASSESSOR} alt="Ability Assessment of Older Adults from Practitioners' Perspectives in China" className="image" />
                  </div>
                  
                  <div className="right">
                    <h2>"Watch, Smell, Ask, Touch": Practices, Challenges, and Technological Support in Ability Assessment of Older Adults from Practitioners' Perspectives in China</h2>
                    <p className="authors"><b>Zhongyue Zhang</b>, Yuru Huang, Mengyang Wang, Mingming Fan</p>
                    <p className="conference-details">
                    <span className="short-conference-name">CHI'25</span> In Proceedings of the CHI Conference on Human Factors in Computing Systems, In Press
                    </p>
                    <div className="button-container"></div>
                  </div>
                </div>
                <br></br>
              <div className="each-research">
                  <div className="left">
                    <img src={REMINIBUDDY} alt="Toward Designing a Multi-agent Conversational Reminiscence System with Digital Artifacts for Older Adults" className="image" />
                  </div>
                  <div className="right">
                    <h2>Chorus of the Past: Toward Designing a Multi-agent Conversational Reminiscence System with Digital Artifacts for Older Adults</h2>
                    <p className="authors">Jingwei Sun, <b>Zhongyue Zhang</b>, Mengyang Wang, Nianlong Li, Yan Xiang, Liuxin Zhang, Yu Zhang, Qianying Wang, Mingming Fan</p>
                    <p className="conference-details">
                    <span className="short-conference-name">CHI'25</span> In Proceedings of the CHI Conference on Human Factors in Computing Systems, In Press
                    </p>
                    <div className="button-container"></div>
                  </div>
                </div>
                <br></br>
              <div className="each-research">
                  <div className="left">
                    <img src={CODESIGNREMINSICENCE} alt="Co-design Photo-based Reminiscence with Older Adults" className="image" />
                  </div>
                  <div className="right">
                    <h2>Understanding and Co-designing Photo-based Reminiscence with Older Adults</h2>
                    <p className="authors"><b>Zhongyue Zhang</b>, Lina Xu, Xingkai Wang, Xu Zhang, Mingming Fan</p>
                    <p className="conference-details">
                    <span className="short-conference-name">CSCW'25</span> In Proceedings of the ACM on Human-Computer Interaction, CSCW, In Press
                    </p>
                    <div className="button-container">
                    <a href="https://arxiv.org/abs/2411.00351" className="custom-button" target="_blank">arXiv</a>
    </div>
                  </div>
                </div>
                <br></br>
                <div className="each-research">
                  <div className="left">
                    <img src={VRSEARCH} alt="VR Search" className="image" />
                  </div>
                  <div className="right">
                    <h2>Toward Facilitating Search in VR With the Assistance of Vision Large Language Models</h2>
                    <p className="authors">Chao Liu, Clarence Chi San Cheung, Mingqing Xu, <b>Zhongyue Zhang</b>, Mingyang Su, Mingming Fan</p>
                    <p className="conference-details">
                    <span className="short-conference-name">VRST'24</span> In Proceedings of ACM Symposium on Virtual Reality Software and Technology
                    </p>
                    <div className="button-container">
                  
                    <a href={`${process.env.PUBLIC_URL}/papers/VRST24-VR-Search-LLM.pdf`} className="custom-button" target="_blank" rel="noopener noreferrer">PDF</a>
      <a href="https://doi.org/10.1145/3641825.3687742" className="custom-button" target="_blank">DOI</a>
    </div>
                  </div>
                </div>
                <br></br>
                <div className="each-research">
                  <div className="left">
                    <img src={COLOR} alt="Color" className="image" />
                  </div>
                  <div className="right">
            
                    <h2>The Effect of Reading Background Colour on Human Cognitive Performance Based on Multi-Modal Data Analysis - A Study of Gender Differences</h2>
                    <p className="authors">Lina Xu, Dehan Jia, <b>Zhongyue Zhang</b>, Leyan Huang, Guobin Xia, Luwen Yu</p>
                    <p className="conference-details">
                    <span className="short-conference-name">AIC'24</span> In AIC 2024 Midterm Meeting: Color Design, Communication and Marketing, In Press
                    </p>
                    <div className="button-container">
                  

      <span className="paper-award">🏆 Best Paper Award</span>
    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
      );
      
};

export default Research;
