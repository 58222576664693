import React from "react";

const News = () => {
  return (
    <div className="each-container" id="news-container">
      <div className="awards-part">
        <div id="news">
          <div className="title">News</div>
          <div className="scrollable-content">
          <ul>
          <li>
              <b>Jan 2025</b> Two papers were accepted by {" "}
              <a
                href="http://chi2025.acm.org"
                target="_blank"
              >
                CHI 2025
              </a>{" "}. Congrats to my co-authors!
            </li>
          <li>
              <b>Dec 2024</b> I'm happy to share that our paper on understanding photo-based reminiscence for older adults (my first first-authored paper!) has been accepted to {" "}
              <a
                href="https://cscw.acm.org/2025/"
                target="_blank"
              >
                CSCW 2025
              </a>{" "}. A big thank you to everyone who contributed to this work. 😊
            </li>
          <li>
              <b>Sept 2024</b> Our paper on gender differences in the impact of color on cognitive performance received the Best Paper Award at {" "}
              <a
                href="https://aic2024.org"
                target="_blank"
              >
                AIC 2024
              </a>{" "}
            </li>
            <li>
              <b>Aug 2024</b> Our paper on exploring vision large language models to facilitate search in VR was accepted by {" "}
              <a
                href="https://vrst.hosting.acm.org/vrst2024/"
                target="_blank"
              >
                VRST 2024
              </a>{" "}
            </li>
          <li>
              <b>Aug 2023</b> Exited to pursue my Ph.D. at HKUST(GZ) working with {" "}
              <a
                href="https://www.mingmingfan.com"
                target="_blank"
              >
                Prof. Mingming Fan
              </a>{" "}
            </li>
            <li>
              <b>Dec 2022</b> Our urban farming design project "Pebble" is{" "}
              <a
                href="https://naturexdesign.tealeaves.com/pebble-the-ritual-of-urban-farming/"
                target="_blank"
              >
                featured
              </a>{" "}
              in NATURE X DESIGN by TEALEAVES
            </li>
            <li>
              <b>July 2022</b> I started my masters in{" "}
              <a href="https://design.engineering.brown.edu" target="_blank">
                Design Engineering
              </a>{" "}
              at Brown and RISD
            </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
